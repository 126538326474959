.coursePriceLabel {
    display: flex;
    gap: var(--gap-list-smaller);
}

@media only screen and (max-width: 1125px) {
    .coursePriceLabel {
        flex-direction: column;
    }
}
