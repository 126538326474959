.starRatingContainer {
    display: inline-block;

    .star {
        display: inline;
        padding-inline-end: var(--padding-tiny);
        height: var(--padding-small);
    }

    .rating {
        font-weight: var(--font-weight-bold);
    }
    .count {
        padding-inline-start: var(--padding-tiny);
        color: var(--text-muted);
    }
}
