.courseRowlink {
    width: 100%;
    color: var(--text);
    height: 100%;
    &:hover {
        h3 {
            color: var(--blue-hover);
        }
        img {
            opacity: 0.9;
            transition: all 0.3s;
        }
    }
}

.courseRow {
    display: grid;
    grid-template-columns: 107px 1fr;
    align-items: center;
    padding-block: var(--padding);
    border-bottom: 1px solid var(--border);
    gap: var(--padding-small);
}

.courseRowDetails {
    flex-grow: 2;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    h3 {
        margin: 0;
        overflow-x: hidden;
    }
    .courseRowLeftDetails {
        display: flex;
        flex-direction: column;
        gap: var(--gap-list-smaller);
    }
    .coursePrice {
        display: flex;
        align-self: flex-end;
        font-weight: var(--font-weight-bold);
        margin-block-start: var(--gap-list-smaller);
        gap: var(--gap-list-smaller);
    }
}

@media screen and (min-width: 767px) {
    .courseRowDetails {
        grid-template-columns: 1fr max-content;
    }
}

.courseRowImage {
    position: relative;
    height: 60px;
    width: 107px;
    img {
        border-radius: var(--border-radius);
        object-fit: cover;
        object-position: center;
        box-shadow: var(--card-box-shadow);
    }
}

.warningBadge {
    color: var(--text);
    font-weight: var(--font-weight-bolder);
    background-color: var(--yellow);
    border-radius: var(--rounded-corners-pill);
    padding: var(--padding-tiny) var(--padding-small);
    display: inline-block;
    width: fit-content;
}
